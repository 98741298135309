
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { useStoryblokState, getStoryblokApi, StoryblokComponent, } from "@storyblok/react";
import Custom404Block from "@/common/components/Story/Pages/Custom404";
import Layout from "@/common/components/Layout";
import Head from "next/head";
import React from "react";
export default function Custom404({ data, locale, settings }) {
    return (<>
      <Head></Head>
      <Layout settings={settings}>
        <Custom404Block blok={data.data.stories[0].content} seoTitle={data?.data?.stories[0].name} locale={locale}/>
      </Layout>
    </>);
}
const getStaticProps = async ({ locale, query }) => {
    const storyblokApi = getStoryblokApi();
    let isProduction = process.env.SITE_ENV === "production";
    let response404 = null;
    try {
        response404 = await storyblokApi.get("cdn/stories", {
            version: isProduction ? "published" : "draft",
            starts_with: "custom404",
            language: locale
        });
    }
    catch (error) {
        console.error("Error fetching custom404 page:", error);
    }
    let responseSettings = null;
    try {
        responseSettings = await storyblokApi.get("cdn/stories/settings", {
            version: isProduction ? "published" : "draft",
            language: locale
        });
    }
    catch (error) {
        console.error("Error fetching settings:", error);
    }
    return {
        props: {
            data: response404,
            locale,
            settings: responseSettings ? responseSettings.data.story : null
        }
    };
};

    async function __Next_Translate__getStaticProps__1944becb8eb__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1944becb8eb__ as getStaticProps }
  